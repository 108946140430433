/* You can add global styles to this file, and also import other style files */

:root {
    --white-color: rgba(255, 255, 255, .9);
}

body {
    margin: 0;
    padding: 0;
    background-color: #fafafa;
}

html, body {
    height: 100%;
}

.fuzzy {
    background-color: rgba(50, 50, 50, .95);
}

.fuzzy-container {
    padding: 30px 20px;
    color: var(--white-color);
    margin-bottom: 20px;
    min-height: 315px;
}

.fuzzy-container h1, .fuzzy-container h2, .fuzzy-container h3 {
    color: var(--white-color);
    font-family: "open sans", sans-serif;
}

@supports((backdrop-filter: blur(10px)) or (-webkit-backdrop-filter: blur(10px))) {
    .fuzzy {
        background-color: hsla(0, 0%, 58.8%, .5);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
    }
}

.fuzzy-container a {
    color: var(--white-color);
    text-decoration: none;
}

.badge {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: #777;
    border-radius: 10px;
}

.top-link {
    text-transform: uppercase;
    margin-top: 5px;
    margin-left: 20px;
    display: inline-block;
    position: relative;
    transition: .5s;
    text-decoration: none;
    color: var(--white-color);
    font-size: 14px;
}

.top-link:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -3px;
    left: 0;
    background-color: #f44336;
    visibility: hidden;
    transform: scaleX(0);
    transition: all .3s ease-in-out 0s;
}

.star-ratings-sprite {
    font-size: 0;
    line-height: 0;
    overflow: hidden;
    text-indent: -999em;
    width: 330px;
    display: table;
    margin: 30px auto;
}

.star-ratings-sprite-rating {
    background-position: 0 100% !important;
    float: left;
    display: block;
    transition: width .5s ease-out;
    margin-top: -1px;
}

.star-ratings-sprite, .star-ratings-sprite-rating {
    background: url(assets/images/star-rating-sprite.png) repeat-x;
    height: 65px;
}

.top-link.selected:before, .top-link:hover:before {
    visibility: visible;
    transform: scaleX(1);
}

.fuzzy-container .data-label {
    color: var(--white-color);
}

.white-text {
    color: var(--white-color);
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 100;
    margin: 0;
    color: #4d565a;
}

.white {
    color: white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, .5);
}

.failed-toast {
    background-color: #b2761c !important;
}

.info {
    background-color: rgb(245, 245, 245);
    padding: 10px;
    border: 1px solid rgb(200, 200, 200);
}

/* .mat-form-field {
    margin-top: 15px;
}

.mat-form-field-flex {
    background-color: #fff;
}

.mat-form-field-infix {
    padding: .3375em .2em;

    background-color: transparent;
    border: 1px solid rgb(230, 240, 230);
    border-radius: 4px;
} */

input.mat-input-element {
    background-color: transparent !important;
    padding: 5px;
}

/* .mat-form-field-appearance-legacy {
    padding-left: 7px;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: transparent;
}

.mat-form-field-label-wrapper {
    overflow: visible !important;
}

.mat-form-field-label {
    padding-top: 2px;
    padding-left: 5px;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.68125em) scale(0.75) perspective(100px) translateZ(0.001px);
} */

.soft-dark {
    border-radius: 6px;
    background: #f0f0f0;
    box-shadow: 5px 5px 3px #dfdfdf, -5px -5px 3px #ffffff;
}

.soft-light-bg {
    background-color: #fafafa;
}

.soft-light {
    border-radius: 6px;
    background: #fafafa;
    box-shadow: 5px 5px 5px #e9e9e9, -5px -5px 3px #ffffff;
}

.soft-dark-button {
    border-radius: 4px !important;
    background: #dedede;
    box-shadow: 3px 3px 5px #cecece, -3px -3px 5px #eeeeee !important;
}

.soft-light-button {
    border-radius: 4px !important;
    background: #f0f0f0;
    box-shadow: 3px 3px 5px #dfdfdf, -3px -3px 5px #ffffff !important;
}

h1.large-print, span.large-print {
    font-size: 63px;
    line-height: 40px;
    font-family: bebas-neue, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-shadow: none;
    text-transform: none;
}

.right {
    text-align: right;
}

.padding {
    padding: 10px;
}

.container {
    display: table;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.mat-raised-button[disabled]:not([class*=mat-elevation-z]) {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, .2), 0 0 0 0 rgba(0, 0, 0, .14), 0 0 0 0 rgba(0, 0, 0, .12);
    color: rgb(150, 150, 150);
    text-shadow: none;
    background-color: rgb(175, 175, 175);
    color: rgba(255, 255, 255, .3);
}

.success-toast {
    background-color: rgb(247, 247, 247) !important;
}

.indent {
    margin-left: 15px;
}



button[mat-button], a[mat-button] {
    font-family: "Open Sans";
    font-weight: 100;
    text-transform: uppercase;
}

.block, [block] {
    width: 100%;
    display: block;
}

.large, [large] {
    font-size: 22px;
    padding: 10px 32px !important;
}

mat-spinner.thirty, button mat-spinner, mat-spinner[thirty] {
    display: inline-block;
    height: 30px !important;
    width: 30px !important;
}

mat-spinner.thirty svg, button mat-spinner svg, mat-spinner[thirty] svg {
    height: 30px !important;
    width: 30px !important
}

mat-spinner.twenty, mat-spinner[twenty] {
    display: inline-block;
    height: 20px !important;
    width: 20px !important;
}

mat-spinner.twenty svg, mat-spinner[twenty] svg {
    height: 20px !important;
    width: 20px !important;
}

@media(min-width: 769px) {
    [showxs] {
        display: none;
    }
}

@media(max-width: 768px) {
    [hidexs] {
        display: none;
    }
    button[mblock], a[mblock] {
        display: block;
        width: 100%;
    }
}

.little {
    text-align: right;
    font-size: 28px;
    color: #ef7b00;
}

.page-title {
    display: inline-block;
    text-align: right;
    line-height: 20px;
    text-transform: lowercase;
}

.nomargin, [nomargin] {
    margin: 0;
}

.topmargin40, [topmargin40] {
    margin-top: 40px !important;
}

.topmargin20, [topmargin20] {
    margin-top: 20px !important;
}

.topmargin10, [topmargin10] {
    margin-top: 10px !important;
}

.topmargin30, [topmargin30] {
    margin-top: 30px !important;
}

.bottommargin20, [bottommargin20] {
    margin-bottom: 20px !important;
}

.bottommargin10, [bottommargin10] {
    margin-bottom: 10px !important;
}

.bottommargin30, [bottommargin30] {
    margin-bottom: 30px !important;
}

.bottommargin10, [bottommargin10], .marginbottom10, [marginbottom10] {
    margin-bottom: 10px !important;
}

.bottommargin20, [bottommargin20], .marginbottom20, [marginbottom20] {
    margin-bottom: 20px !important;
}

.bottommargin30, [bottommargin30], .marginbottom30, [marginbottom30] {
    margin-bottom: 30px !important;
}

div[flex], div[aflex] {
    display: flex;
}

div[flex]>*, div[aflex]>* {
    flex: auto;
}

div[flex]>*[nogrow], div[aflex]>*[nogrow] {
    flex: none !important;
}

[nogrow] {
    flex: none !important;
}

div[flex]>*:not(:first-child), div[aflex]>*:not(:first-child) {
    margin-left: 20px;
}

*[flex1] {
    flex: 1 !important;
}

*[flex2] {
    flex: 2 !important;
}

*[flex3] {
    flex: 3 !important;
}

*[flex15] {
    flex: 1.5 !important;
}

button.mat-primary.mat-mdc-raised-button:not(:disabled), a.mat-mdc-raised-button:not(:disabled) {
    background-color: #ec7c23;    color: #FFFFFF !important;
    border-width: 0px !important;
    border-radius: 14px !important;
    font-size: 18px;
    background-image: linear-gradient(99deg, #dd823b 0%, #ff612f 100%);
    transition: all 500ms ease 0ms;
    padding-top: 12px !important;
    padding-right: 45px !important;
    padding-bottom: 12px !important;
    padding-left: 45px !important;
    text-transform: none !important;
    box-shadow: none;}

.mat-primary.small-primary {
    font-size: 14px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    border-radius: 5px;
}

[color=primary]:disabled {
    background-color: rgb(200, 200, 200) !important;
    background-image: none !important;
    cursor: not-allowed;
    transform: none !important;
}

button[mat-raised-button], a[mat-raised-button] {
    border-radius: 0;
}

.data-label {
    font-size: 12px;
    text-transform: uppercase;
    /* font-weight: bold; */
    color: #0C6291;
}

.big-data {
    font-size: 54px;
}

.ribbon {
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 100px;
    height: 100px;
    text-align: right;
}

.ribbon span {
    font-size: 10px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 120px;
    display: block;
    background: #79A70A;
    background: linear-gradient(#F79E05 0%, #8F5408 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 27px;
    right: -24px;
}

.ribbon span::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #8F5408;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #8F5408;
}

.ribbon span::after {
    content: "";
    position: absolute;
    right: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #8F5408;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #8F5408;
}

.gray {
    background-color: rgba(242, 242, 242, .9);
}

.sub-heading {
    font-size: 24px;
    color: rgba(0, 0, 0, .4);
}

.mat-dialog-container, .mat-tab-body-content {
    overflow-x: hidden !important;
}

.mat-tab-body-content,
.mat-mdc-tab-body-content {
    overflow: hidden !important;
}

.dashed-left-and-right {
    color: rgb(200, 200, 200);
    position: relative;
    font-size: 13px;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.dashed-left-and-right:before {
    right: calc(50%  + 50px);
    margin-right: 15px;
    
}
.dashed-left-and-right:after {
    left: calc(50%  + 50px);
    margin-left: 15px;
}
.dashed-left-and-right::before, .dashed-left-and-right::after {
    content: "";
    position: absolute;
    height: 5px;
    border-bottom: 1px solid rgb(200, 200, 200);
    border-top: 1px solid rgb(200, 200, 200);
    top: 6px;
    width: 100px;
}

.dialog-title {
    font-family: 'Open Sans';
    color: rgb(200, 200, 200);
    text-transform: lowercase;
    font-size: 36px;
    line-height: 36px;
}

.overflow-visible .mat-tab-body-wrapper, .overflow-visible .mat-tab-body-content {
    overflow: hidden !important;
}

.sub-heading .orange {
    color: #ef7b00;
}

.center {
    text-align: center;
}

mat-form-field {
    display: block !important;
}

.hide-tabs mat-tab-header {
    display: none;
}

table {
    width: 100%;
    margin-right: 20px;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
    padding: .3em 0 .3em 0;
}

table thead {
    padding: 10px 5px;
    color: rgba(0, 0, 0, .5);
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
}

table thead tr th {
    padding: 6px 8px;
}

table tbody tr {
    color: rgba(0, 0, 0, .5);
}

table tbody tr td {
    padding: 7px 8px;
}

table.table {
    border-collapse: collapse;
}

button.mdc-button {
    display: inline-block;
    height: auto;
}

div.table-header, table.table>thead tr {
    display: flex;
    /*background: linear-gradient(to bottom, #d3d3d3 1%,#f6f6f6 100%);*/
    border-bottom: 3px solid #4e575c;
}

div.table-header>div, table.table>thead th {
    flex: 1;
    padding: 10px 5px;
    color: rgba(0, 0, 0, .5);
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
}

div.table-row, table.table>tbody tr {
    border-bottom: 1px solid #4e575c;
    color: rgba(0, 0, 0, .5);
    display: flex !important;
}

div.table-row>div, table.table>tbody tr>div {
    padding: 10px 5px;
    flex: 1;
}

table.table>tbody tr, table.table>thead tr {
    display: table-row !important;
}

.show-mobile {
    display: none;
}

.hide-mobile {
    display: block;
}

@keyframes slideInUpSmall {
    0% {
        transform: translateY(15%) scale(.75);
        opacity: 0;
    }
    60% {
        transform: translateY(1%) scale(.95);
        opacity: .6;
    }
    100% {
        transform: translateY(0%) scale(1);
        opacity: 1;
    }
}

.slide-in-up {
    animation: slideInUpSmall .5s ease-out;
}

@media(max-width: 768px) {
    .show-mobile {
        display: block;
    }
    .hide-mobile {
        display: none;
    }
}

@media (max-width: 960px) {
    [flex], div[flex] {
        display: block;
    }
    div[flex]>*:not(:first-child) {
        margin-left: 0;
    }
    .side-column {
        width: 100%;
    }
    h1 span.big {
        font-size: 34px !important;
    }
    .page-title {
        line-height: 24px;
    }
    [mobile-block] {
        display: block;
        width: 100%;
    }
    .container {
        display: block;
        width: auto;
        max-width: 1200px;
        margin: 0 auto;
    }
}

@media(max-width: 768px) {
    /* .cdk-overlay-pane {
        max-width: 96vw !important; 
        width: 96vw !important;
        height: 80vh !important;
    } */
}